/* @import "~cisco-ui/dist/css/cui-standard.min.css"; */
@import "./assets/css/cui-standard.min.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";

@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "../node_modules/angular2-multiselect-dropdown/themes/default.theme.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  overflow: auto;
}

/* .ag-theme-alpine {
  font-family: --cui-font-stack;
} */
.text-color {
  color: #00bceb;

}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.backdrop-dialog {
  background: #ffffff;
  opacity: 0.85 !important;
}

mat-dialog-container {
  overflow: visible !important;
}

.full-width-dialog .mat-dialog-container {
  width: 100vw !important;
}

.full-max-width-dialog .mat-dialog-container {
  width: 100vw !important;
  max-width: 700px;
}

.full-max-width-dialog-500 .mat-dialog-container {
  width: 100vw !important;
  max-width: 500px;
}

#curvepoint .ag-header-cell-label {
  justify-content: center;
}

.text-danger {
  border-color: red !important;
  box-shadow: 0 0 3px red !important;
}

.select-danger {
  border-color: red !important;
  box-shadow: 0 0 3px red !important;
}

.text-normal {
  border-color: #00bceb !important;
}

.width-dialog-35 .mat-dialog-container {
  width: 35vw !important;
}

.width-dialog-40 .mat-dialog-container {
  width: 40vw !important;
}

mat-dialog-container {
  overflow: visible !important;
}

.width-dialog-100 .mat-dialog-container {
  width: 100vw !important;
  max-width: 100vw;
}

.width-dialog-100 .mat-dialog-content {
  max-height: 85vh;
}

.padding-left-6-right-12 {
  padding-right: 6px;
  padding-left: 12px;
}

#loading-dots {
  position: fixed;

  bottom: 50%;
  max-height: 100vh !important;
  right: 50%;
}

.text-center-custom {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nsdicon-close {
  vertical-align: inherit;
}

.margin_auto_t_b {
  margin: auto 0 auto 0;
}

.storage .ngx-dropdown-list-container {
  position: relative !important;
}

.ngx-select-danger .ngx-dropdown-button {
  border: 1px solid red !important;
  box-shadow: 0 0 3px red !important;
}


.ngx-select-normal .ngx-dropdown-button {
  border: 1.6px solid #00bceb !important;
}

.block-ui-wrapper {
  background: rgba(0, 0, 0, 0.5) !important;
}

.cutom-padding-l7-r16 {
  padding-left: 7px;
  padding-right: 16px;
}

.modal__close {
  display: none !important;
}

.cui .btn.btn--ghost.active,
.cui .btn.btn--ghost.selected,
.cui .btn.btn--ghost:active {
  background-color: #00a3d2 !important;
  border-color: #00a3d2 !important;
  color: black !important;
}

.ng-select .ng-clear-wrapper {
  display: none;
}

.select-danger {
  border-color: red !important;
  box-shadow: 0 0 6px red !important;
  border-radius: 4px;
}

/* {
  border-color: red !important;
  box-shadow: 0 0 3px red !important;
} */
.select-normal {
  border-color: #00bceb !important;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ng-select .ng-select-container {
  border: none;
  align-items: center;
}

.margin-auto-0 {
  margin: auto 0;
}


/* ng2 validation styles */
.ng2-select-danger .selected-list {
  border: 1px solid red !important;
  box-shadow: 0 0 3px red !important;
}

.ng2-select-normal .selected-list {
  border: 1.6px solid #00bceb !important;
}

.ng2-select-danger .c-btn {
  border: none !important;
}

.ng2-select-normal .c-btn {
  border: none !important
}

.pure-checkbox input[type="checkbox"]:checked+label:before {
  background: #00bceb;
}

.pure-checkbox input[type="checkbox"]+label:before {
  color: #00bceb;
  border: 1px solid #00bceb;
}

.padding-set-input {
  padding: 0px 15px 0px 9px;
}


[data-balloon]:after {
  font-size: 1.4rem !important;
}

[data-balloon][data-balloon-length='xlarge']:after {
  width: 500px !important;
  ;
}

mat-error {
  display: block !important;
}
@media print {
  @page {
    size: landscape;
  }
  .final-results-container, .final-results-container * {
    visibility: visible;
    zoom: 90%;
  }
  button {
    visibility: hidden;
  }
}